import React from 'react';
import { Box, AspectRatio, Text, Button, Center, Link, Heading, Image } from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE, AKAD, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import { ENABLE_LIVE_STREAMING } from '@/constants/feature-flags';
import { TEXT_PROPS, DEFAULT_PROPS } from '@/constants/default-props';
import { IMG_FLOWER } from '@/constants/assets';

import txt from './locales';

function YoutubeLiveSection({ ...rest }) {
  const lang = useLang();

  return (
    <Box zIndex="3" position="relative" bgColor="bgPrimary" {...rest}>
      {/* Title & Desctiption Section */}
      <Box zIndex="2" textAlign="center" position="relative" padding="32px 24px">
        <WithAnimation type="zoom">
          <Heading {...TEXT_PROPS.title} mb="24px">
            Prewedding Video
          </Heading>
        </WithAnimation>
        <WithAnimation type="zoom">
          <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg" boxShadow="xl">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '8px' }}
            />
          </AspectRatio>
        </WithAnimation>
        <Box padding="16px">
          {ENABLE_LIVE_STREAMING && (
            <WithAnimation>
              <Text
                textAlign="center"
                color="mainColorText"
                dangerouslySetInnerHTML={{
                  __html: `Live on <br /> ${AKAD.fullDate} | ${AKAD.time}`,
                }}
              />
            </WithAnimation>
          )}
          <WithAnimation type="zoom">
            <Text {...TEXT_PROPS.body}>{txt.problem[lang]}</Text>
          </WithAnimation>
          <WithAnimation type="zoom">
            <Center mt="16px">
              <Link href={YOUTUBE_LINK} target="_blank">
                <Button size="sm" {...DEFAULT_PROPS.button}>
                  {txt.open[lang]}
                </Button>
              </Link>
            </Center>
          </WithAnimation>
        </Box>
      </Box>
      {/* <WithAnimation type="zoom">
        <Image w="120px" src={IMG_LOGO_WEDDING} margin="0 auto" mt="24px" />
      </WithAnimation> */}
      <Image
        w="360px"
        src={IMG_FLOWER.left}
        transform="rotate(30deg)"
        opacity={0.1}
        pos="absolute"
        bottom="-160px"
        right="-150px"
      />
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
